<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="my-aq-list" style="min-height: 100vh;" ref="listView">
			<router-link :to="'/ask/detail/'+item.askid" class="aq-item" v-for="(item, index) in dataList" :key="index">
				<div class="title-w">
					<span class="category" v-text="'#' + item.illnesscategorycode" @click.stop="linkCategory(item.illnesscategoryid)"></span>
					<h2 class="title" v-text="item.title"></h2>
				</div>
				<p class="content" v-text="item.note"></p>
			</router-link>
		</div>
		<loading v-if="loading"/>
	</van-pull-refresh>
</template>

<script>
	import Loading from '@/layouts/Loading.vue'
	import my_ask from '@/views/mixins/my_ask'
	export default {
		mixins: [
			my_ask
		],
		components: {
			Loading
		},
		data(){
			return {
				loading: true,
				isRefresh: false,
				isLoadMore: false,
				isLoadFinished: false,
				pageNo: 1,
				pageSize: 10,
				maxPage: 1,
				clientId: this.$store.getters.clientId,
				dataList: [],
				loadMore: false,
			}
		},
		mounted: function(){
			this.onRefresh()
		},
		methods: {
			onRefresh() {
				this.pageNo = 1
				this.loading = true
				this.isLoadMore = false
				this.isLoadFinished = false
				this.loadData({clientid: this.clientId, pageNo: this.pageNo, pageSize: this.pageSize}, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
				})
			},
			handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData({clientid: this.clientId, pageNo: this.pageNo+1, pageSize: this.pageSize}, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
			linkCategory: function(categoryid) {
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	.my-aq-list {
		display: flex;
		flex-direction: column;
	}

	.my-aq-list .aq-item {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		margin: 16px 24px 0px 16px;
		padding: 12px;
	}

	.my-aq-list .aq-item .row {
		display: flex;
		flex-direction: row;
	}
	.my-aq-list .aq-item .title-w {
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.my-aq-list .aq-item .title-w .category {
		display: inline;
		color: #FF8000;
		line-height: 43px;
		font-size: 34px;
		font-weight: bold;
	}

	.my-aq-list .aq-item .title-w .title {
		display: inline;
		color: #231F20;
		line-height: 43px;
		font-size: 34px;
		font-weight: bold;
		margin-left: 8px;
	}

	.my-aq-list .aq-item .gender,
	.my-aq-list .aq-item .age,
	.my-aq-list .aq-item .create-time {
		color: #969696;
		font-size: 26px;
		line-height: 25px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .gender {
		margin-right: 8px;
	}

	.my-aq-list .aq-item .age {
		margin-right: 28px;
	}

	.my-aq-list .aq-item .content {
		line-height: 39px;
		color: #696969;
		font-weight: 400;
		font-size: 28px;
		margin-top: 10px;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.my-aq-list .aq-item .answer-list {
		background-color: #EFF4F9;
		margin: 30px -17px;
		padding-bottom: 30px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item {
		display: flex;
		flex-direction: column;
		padding: 30px 16px 0px 16px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .icon-doctor {
		width: 32px;
		height: 32px;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .doctor-name {
		margin-left: 15px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .answer-time {
		margin-left: 19px;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .answer-list > .answer-item .answer-content {
		margin-left: 47px;
		color: #231F20;
		font-size: 28px;
		font-weight: 400;
		margin-top: 6px;
	}

	.my-aq-list .aq-item .btn-collapse {
		color: #36C4D0;
		font-weight: 400;
		font-size: 26px;
	}

	.my-aq-list .aq-item .btn-expand {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		display: none;
	}

	.my-aq-list .aq-item .btn-expand > .hint {
		color: #231F20;
		font-size: 26px;
		font-weight: 400;
		line-height: 25px;
		display: inline-block;
		height: 25px;
		padding-top: 2px;
	}

	.my-aq-list .aq-item .btn-expand > .icon-answer {
		width: 25px;
		height: 23px;
		margin-right: 23px;
	}

	.my-aq-list .aq-item .sub-question {
		font-size: 28px;
		color: #231F20;
		font-weight: bold;
		line-height: 32px;
		margin-top: 21px;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.my-aq-list .aq-item .sub-question > .hint {
		color: #36C4D0;
	}

	.my-aq-list .aq-item .operate {
		display: flex;
		flex-direction: row;
	}

	.my-aq-list .aq-item .btn-sub-question {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 32;
	align-items: center;
	margin-left: 93px;
	}

	.my-aq-list .aq-item .btn-sub-question > .icon-sub-question {
		width: 24px;
		height: 22px;
		margin-right: 16px;
		vertical-align: middle;
	}

	.my-aq-list .aq-item .btn-sub-question > .hint {
		color: #231F20;
		font-size: 26px;
		font-weight: 400;
	}

	.my-aq-list .aq-item .btn-sub-question.disable > .hint {
		color: #BFBFBF;
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}
	
	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
	//loadmore end

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

</style>
